button {
  padding: 0px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  background-color: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

h3,
h4 {
  margin-bottom: 0;
}

#page-body {
  max-width: 76%;
  margin: auto;
  padding-top: 50px;
}

.form {
  margin: auto;
  padding: 100px 30px 0;

  @media (min-width: 800px) {
    max-width: 60%;
  }
}

.card-columns {
  padding-top: 64px;
}

.input-range {
  margin-bottom: 20px;
  margin-top: 20px;
}

.carousel-content {
  position: absolute;
  bottom: 60%;
  left: 37%;
  font-size: 500%;
  z-index: 20;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

h1,
.h1 {
  margin-bottom: 0.5rem;
  font-family: ‘Oswald’, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

img {
  position: relative;
  width: auto;
  /* min-width: 150px; */
  max-height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  display: inline-block;
  justify-content: center;
  display: flex;
}

.home {
  background: url("https://firebasestorage.googleapis.com/v0/b/findmemovie.appspot.com/o/poster2.jpg?alt=media&token=137c1130-c658-490f-8aec-2b58c40e3c06")
    no-repeat fixed center;
  background-size: cover;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* text-align: center;
  padding-top: 300px; */
}

.img-fluid {
  max-height: 80px;
}

/* * {
  box-sizing: border-box;
} */

/* Container needed to position the overlay. Adjust the width as needed */
.poster {
  position: relative;
}

/* Make the image to responsive */
.image {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 13px;
}

/* When you mouse over the container, fade in the overlay title */
.poster:hover .overlay {
  opacity: 1;
}

nav {
  z-index: 9999;
}

.idpage {
  background: url("https://firebasestorage.googleapis.com/v0/b/findmemovie.appspot.com/o/solid-color-blue-one-colour-plain-single-1920x1080-c-182243-f-24-2.jpg?alt=media&token=93d04435-e6ef-498f-b394-b8a9cf081762")
    no-repeat fixed center;
  background-size: cover;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* color: white; */
  /* text-align: center;
  padding-top: 300px; */
}

.card-img-top {
  min-width: 150px;
}

.dropdown-menu {
  width: 300px;
}

.nav > li > .dropdown-menu > a:active {
  background-color: transparent !important;
}

.dropdown-toggle {
  background-color: transparent !important;
}

.nav > li > .dropdown-menu > a:active {
  position: static;
}
